export function Button({ children, onClick }) {
  return (
    <button
      type="button"
      className="py-1 text-neutral-600 bg-transparent
        rounded-full outline-none 
        ease-linear transition-all duration-150
        hover:text-white
        hover:bg-neutral-800
        active:bg-neutral-500 
        focus:outline-none"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

// <button
// type="button"
// className="mt-3 px-6 py-2 text-white bg-gray-700 hover:bg-gray-800
// focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800 inline-flex items-center"
// onClick={clickWriteBtn}
// >

export function ButtonFilled({ children, onClick }) {
  return (
    <button
      type="button"
      className="text-white bg-gray-700 
        rounded-full outline-none 
        ease-linear transition-all duration-150
        hover:text-white
        hover:bg-gray-800
        active:bg-gray-500
        focus:outline-none"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export function ButtonFilledDisabled({ children }) {
  return (
    <button type="button" className="text-white bg-gray-400 rounded-full outline-none" disabled>
      {children}
    </button>
  );
}

export function ButtonOutline({ children, onClick }) {
  return (
    <button
      type="button"
      className="py-1 text-gray-500 bg-white
        border border-gray-400 
        rounded-full outline-none 
        ease-linear transition-all duration-150
        hover:text-white
        hover:bg-gray-800
        active:bg-gray-500
        focus:outline-none"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export function ButtonText({ children, onClick }) {
  return (
    <button
      type="button"
      className="text-gray-900 bg-white dark:text-gray-100 dark:bg-neutral-800
        rounded-full outline-none 
        ease-linear transition-all duration-150
        hover:text-white
        hover:bg-gray-700
        active:bg-gray-500
        focus:outline-none"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export function ButtonSvg({ children, onClick }) {
  return (
    <button
      className="py-1 px-1 text-gray-700 bg-transparent
          rounded-full outline-none 
          ease-linear transition-all duration-150
          hover:text-white
          hover:bg-gray-800
          active:bg-gray-500 
          focus:outline-none"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function PostDetail() {
  const location = useLocation();

  useEffect(() => {
    window.location.replace('https://blog.suseona.com' + location.pathname);
  }, []);

  return (
    <div>
      <div className="text-center mt-64 font-serif">
        <small>Redirect to</small> blog.suseona.com
      </div>
    </div>
  );
}

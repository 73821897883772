import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function UserMain() {
  const location = useLocation();
  const username = location.pathname.split('/@')[1].split('/')[0];

  useEffect(() => {
    const htmlTitle = document.querySelector('title');
    htmlTitle.innerText = username;
    window.location.replace('https://blog.suseona.com' + location.pathname);
  }, []);

  return (
    <div>
      <div className="text-center mt-64 font-serif">
        <small>Redirect to</small> blog.suseona.com
      </div>
    </div>
  );
}

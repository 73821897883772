import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Main from 'pages/main';
import UserMain from 'pages/user/main';
import PostDetail from 'pages/post/detail';
import Toast from './components/common/Toast';

import Seo from 'components/common/seo';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
// class="bg-white dark:bg-black"
// root.addClass('bg-white dark:bg-black');

if (localStorage.theme === 'dark') {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        {/*<Seo />*/}
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="tag">
            <Route path=":path2">
              <Route path="hot" element={<Main />} />
              <Route path="trending" element={<Main />} />
              <Route path="latest" element={<Main />} />
            </Route>
          </Route>
          <Route path="@:path1" element={<UserMain />}></Route>
          <Route path="@:path1/feeds" element={<UserMain />}></Route>
          <Route path="@:path1/blogs" element={<UserMain />}></Route>
          <Route path="@:path1/:path2" element={<PostDetail />}></Route>
          <Route path=":path0/@:path1/:path2" element={<PostDetail />}></Route>
          <Route
            path="*"
            element={
              <main style={{ padding: '1rem' }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </HelmetProvider>
    </BrowserRouter>
    <Toast />
  </React.StrictMode>
);
